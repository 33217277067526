import {
  GET_EMOJIS,
  CREATE_EMOJI,
  EMOJI_ERROR
} from "../actions/types";

const initialState = {
  reactions: [],
  reaction: null,
  reactionError: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CREATE_EMOJI:
      return {
        ...state,
        reaction: action.payload,
      };
    case EMOJI_ERROR:
      return {
        ...state,
        reactionError: action.payload,
      };
    case GET_EMOJIS:
      return {
        ...state,
        reactions: action.payload,
      };
    default:
      return state;
  }
}