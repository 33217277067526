import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import iconPlanPro from "../../assets/icon-moeda.svg";
import iconPlanLight from "../../assets/coin-light.png";
import PaymentModal from "./PaymentModal";
import "../../css/paywall.scss";

// const pplans = {
//   1: {
//     title: "Paradigma Fundamentos",
//     subTitle: "Manual de sobrevivência",
//     price: "39,90",
//     icon: iconPlanFund,
//   },

//   2: {
//     title: "Paradigma Técnico",
//     subTitle: "Invista sistematicamente",
//     price: "39,90",
//     icon: iconPlanTec,
//   },

//   3: {
//     title: "Paradigma On Chain",
//     subTitle: "Fale a língua da blockchain",
//     price: "39,90",
//     icon: iconPlanOn,
//   },
// };

const Paywall = ({plan, modalLight}) => {
  const pplan = plan === 3 ? 3 : 5;
  const [selectedPplan, setSelectedPplan] = useState(pplan);
  const [paymentModalVisible, setPaymentModalVisible] = useState(false);
  const togglePaymentModal = () => setPaymentModalVisible((c) => !c);

  return (
    <div className="content-paywall">
      <div className="item-plan item-planPro">
        <div className="pay-content">
          <img src={pplan === 3 ? iconPlanLight : iconPlanPro} alt="icon" />
          <h3 className="title">{`Paradigma ${pplan === 3 ? "Light" : "Pro"}`}</h3>
          {/* <p className="subtitle">Master kit de inteligência</p> */}
          <p className="total-price">
            {pplan === 3 ? "R$ 598,80" : "R$ 1438,80"}
          </p>
          <p className="price">
            {`(equivale R$ ${pplan === 3 ? "49,90" : "119,90"}/mês)`}
          </p>
          <Button
            color="primary"
            size="large"
            onClick={() => {
              togglePaymentModal();
              setSelectedPplan(pplan);
            }}
          >
            Assinar
          </Button>

          <a
            href="https://paradigma.education/pro"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: "underline",
              color: "#6b6978"
            }}
          >
            Veja detalhes do plano
            {/* <span role="img" aria-label="emoji">
              📜
            </span> */}
          </a>
        </div>
      </div>
      {/* {pplan !== 4 && (
        <div className="item-plan item-planLight">
          <img src={iconLock} className="lockItem" alt="icon" />

          <div className="pay-content">
            <hr />
            <span className="firstIntro">Para acessar este conteúdo</span>

            <img src={pplans[pplan].icon} alt="icon" />
            <h3 className="title">{pplans[pplan].title}</h3>
            <p className="subtitle">{pplans[pplan].subTitle}</p>
            <p className="price">
              R$ {pplans[pplan].price} /<span>mês</span>
            </p>
            <Button
              color="primary"
              size="lg"
              onClick={() => {
                togglePaymentModal();
                setSelectedPplan(pplan);
              }}
            >
              Assinar
            </Button>

            <a
              href="https://paradigma.education/pro"
              target="_blank"
              rel="noopener noreferrer"
            >
              Veja detalhes do plano{" "}
              <span role="img" aria-label="emoji">
                📜
              </span>
            </a>
          </div>
        </div>
      )} */}
      <PaymentModal
        visible={paymentModalVisible}
        handleClose={togglePaymentModal}
        selectedpaymentplan={selectedPplan}
        setSelectedPplan={setSelectedPplan}
        modalLight={modalLight}
      />
    </div>
  );
};

export default Paywall;
