const initialState = {
  currentVideo: null,
  nextVideo: null,
  currentVideoIndex: 0,
  nextVideoIndex: 0,
  videos: [],
  module: null,
  player: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "SET_CURRENT_VIDEO":
      return {
        ...state,
        currentVideo: action.payload.currentVideo,
        currentVideoIndex: action.payload.currentVideoIndex,
      };
    case "SET_NEXT_VIDEO":
      return {
        ...state,
        nextVideo: action.payload.nextVideo,
        nextVideoIndex: action.payload.nextVideoIndex,
      };
    case "SET_VIDEOS":
      return {
        ...state,
        videos: action.payload,
      };
    case "SET_MODULE":
      return {
        ...state,
        module: action.payload,
      };
    case "SET_VIDEO_PLAYER":
      return {
        ...state,
        player: action.payload,
      };
    default:
      return state;
  }
}
