import React from "react";

import loading from '../../assets/loading.gif';

const PointProgress = ({ item }) => {
  return (
    // <div className={`${item === 'internal' ? 'pointProgress' : ''} lds-grid`}>
    //     <div />
    //     <div />
    //     <div />
    //     <div />
    //     <div />
    //     <div />
    //     <div />
    //     <div />
    //     <div />
    // </div>
    <div className={`${item === 'internal' ? 'pointProgress' : ''} lds-grid`}>
      <img style={{
        width: "40px",
        height: "40px"
      }} src={loading} alt="loading..."/>
    </div> 
  );
}

export default PointProgress;
