import React from "react";
import MaskedInput from "react-text-mask";


const TextMaskCEP = (props) => {
  const { inputRef = () => {}, mask, ...other } = props;

  return (
    <MaskedInput
    {...other}
    ref={(ref) => {
      inputRef(ref ? ref.inputElement : null);
    }}
    mask={[/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/]}
    placeholderChar={"\u2000"}
    />
);
}

export default TextMaskCEP;