const defaultColor = {
  "on-chain": 0,
  derivatives: 5,
  spot: 2,
};

const casasciusChartOptions = {
  chart: {
    backgroundColor: "#151B24",
    height: "350px",
    zoomType: "x",
    reflow: true,
  },
  series: [],
  navigator : {
    enabled :  false
  },
  credits: {
    enabled: false,
  },
  title: {
    style: {
      fontSize: "23px",
      fontWeight: 700,
      color: "#ffffff",
      fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
      textTransform: 'capitalize'
    },
    align: "left",
    y: 20,
    margin: 0
  },

  xAxis : {
    // type:  'datetime',
    // labels: { 
    //   formatter: function() { 
    //     return Highcharts.dateFormat('%b %Y', this.value); 
    //   } 
    // } 
  },

  scrollbar : {
    enabled :  false
  },
  
  rangeSelector: {
    enabled: true,
    inputEnabled: false,
  }, 
  yAxis: {
  },
  legend: {
    enabled: true,
    navigation: {
      enabled: true,
    },
    itemStyle: {
      color: "#666",
      fontSize: "10px",
    },
    itemHoverStyle: {
      color: "#222",
    },
},
  exporting: {
    buttons: {
      contextButton: {
        enabled: false,
      },
      toggle: {
        text: "Período ▾",
        height: 20,
  
        theme: {
          // "stroke-width": 0.5,
          // stroke: "#000000",
          // r: 2,
        },
        menuItems: [
          {
            text: "1M",
            onclick: function () {
              this.rangeSelector.clickButton(0, true);
            },
          },
          {
            text: "3M",
            onclick: function () {
              this.rangeSelector.clickButton(1, true);
            },
          },
          {
            text: "6M",
            onclick: function () {
              this.rangeSelector.clickButton(2, true);
            },
          },
          {
            text: "YTD",
            onclick: function () {
              this.rangeSelector.clickButton(3, true);
            },
          },
          {
            text: "1Y",
            onclick: function () {
              this.rangeSelector.clickButton(4, true);
            },
          },
          {
            text: "All",
            onclick: function () {
              this.rangeSelector.clickButton(5, true);
            },
          },
        ],
      },
      customButton2: {
          text: 'Log',
          onclick: function() {
              this.yAxis[0].update({
                  type: 'logarithmic'
              });
          }
      },
      customButton: {
        text: 'Lin',
        onclick: function() {
            this.yAxis[0].update({
                type: 'linear'
            });
        }
      },
    },
  },
}

const defaultChartOptions = () => {
  const defaultOptions = {
    chart: {
      events: {
        render() {
          const chart = this;
          const colorAxis = chart.colorAxis[0].axisParent.element

          const axisPadding = 10
          const mainSVG = chart.container.children[0]
          const xPos = chart.plotLeft + chart.plotWidth + axisPadding
          const yPos = chart.plotTop + 25;
          mainSVG.appendChild(colorAxis);


          colorAxis.setAttribute('visibility', 'visible')
          colorAxis.setAttribute('transform', `translate(${xPos},${yPos})`)
        }
      },
      marginRight: 0, 
      backgroundColor: "#FFFFFF",
      reflow: true,
      height: "600px",
      zoomType: "x",
    },
    navigator: {
      enabled: false,
    },
    title: {
      text: "3.1 | MVRV",
      style: {
        color: "#ffffff",
        fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
        fontSize: "23px",
        fontWeight: 700
      },
      align: "left",
      y: 20,
      margin: 0
    },
    series: [
      {
        yAxis: 0,
        id: "spotPrice",
        tooltip: { 
          valueDecimals: 2 
        },
        dataGrouping: {
          enabled: false,
        },
        data: [],
        type: "line",
        name: "Preço (à vista)",
        colorKey: "zScore",
        turboThreshold: 0,
        zIndex: 1,
        // marker: {
        //   enabled: true,
        //   radius: 3.5,
        // }
      },
      {
        yAxis: 1, 
        id: "indicator",
        tooltip: { valueDecimals: 4 },
        data: [],
        name: 'title',
        zIndex: 10,
        type: 'line',
        colorIndex: defaultColor['on-chain'],
      },
    ],  
    tooltip: {
      // pointFormatter(){
      //   // const valueDecimals = this.series.options.tooltip.valueDecimals
      //   // return `<span style="color:${this.color}">●</span> ${this.series.name}: <b>${valueDecimals ? this.y.toFixed(valueDecimals) : this.y}</b><br/>
      //   // ${(this.zScore !== undefined) ? `Z-Score: <b>${this.zScore.toFixed(3)}</b>` : ""}`
      // },
      split: true,
      // valueSuffix: suffixe ? " " + suffixe : null,
    },
    colorAxis: [{
      layout: 'vertical',
      stops: [
        [0.01, "rgb(45, 29, 58)"],
        [0.02, "rgb(54, 35, 86)"],
        [0.03, "rgb(61, 41, 110)"],
        [0.04, "rgb(66, 47, 133)"],
        [0.05, "rgb(70, 54, 153)"],
        [0.06, "rgb(73, 60, 170)"],
        [0.07, "rgb(74, 67, 186)"],
        [0.08, "rgb(75, 74, 199)"],
        [0.09, "rgb(75, 81, 211)"],
        [0.1, "rgb(74, 88, 221)"],
        [0.11, "rgb(72, 95, 229)"],
        [0.12, "rgb(70, 102, 235)"],
        [0.13, "rgb(67, 109, 241)"],
        [0.14, "rgb(65, 116, 244)"],
        [0.15, "rgb(62, 123, 247)"],
        [0.16, "rgb(59, 130, 248)"],
        [0.17, "rgb(56, 137, 249)"],
        [0.18, "rgb(53, 144, 248)"],
        [0.19, "rgb(50, 150, 247)"],
        [0.2, "rgb(47, 157, 245)"],
        [0.21, "rgb(45, 164, 242)"],
        [0.22, "rgb(42, 170, 239)"],
        [0.23, "rgb(41, 176, 235)"],
        [0.24, "rgb(39, 182, 230)"],
        [0.25, "rgb(38, 188, 225)"],
        [0.26, "rgb(37, 194, 220)"],
        [0.27, "rgb(37, 199, 214)"],
        [0.28, "rgb(37, 205, 208)"],
        [0.29, "rgb(38, 210, 202)"],
        [0.3, "rgb(39, 215, 196)"],
        [0.31, "rgb(41, 219, 189)"],
        [0.32, "rgb(43, 224, 183)"],
        [0.33, "rgb(45, 228, 176)"],
        [0.34, "rgb(49, 232, 170)"],
        [0.35, "rgb(52, 235, 163)"],
        [0.36, "rgb(56, 238, 157)"],
        [0.37, "rgb(61, 241, 150)"],
        [0.38, "rgb(66, 244, 144)"],
        [0.39, "rgb(71, 246, 138)"],
        [0.4, "rgb(77, 248, 132)"],
        [0.41, "rgb(83, 250, 126)"],
        [0.42, "rgb(89, 251, 120)"],
        [0.43, "rgb(96, 252, 114)"],
        [0.44, "rgb(103, 253, 109)"],
        [0.45, "rgb(110, 254, 104)"],
        [0.46, "rgb(118, 254, 99)"],
        [0.47, "rgb(125, 253, 94)"],
        [0.48, "rgb(133, 253, 89)"],
        [0.49, "rgb(141, 252, 85)"],
        [0.5, "rgb(149, 251, 81)"],
        [0.51, "rgb(156, 249, 77)"],
        [0.52, "rgb(164, 247, 73)"],
        [0.53, "rgb(172, 245, 69)"],
        [0.54, "rgb(180, 242, 66)"],
        [0.55, "rgb(187, 240, 63)"],
        [0.56, "rgb(195, 236, 60)"],
        [0.57, "rgb(202, 233, 58)"],
        [0.58, "rgb(209, 229, 55)"],
        [0.59, "rgb(216, 225, 53)"],
        [0.6, "rgb(222, 221, 50)"],
        [0.61, "rgb(228, 216, 48)"],
        [0.62, "rgb(234, 211, 46)"],
        [0.63, "rgb(239, 206, 45)"],
        [0.64, "rgb(244, 201, 43)"],
        [0.65, "rgb(248, 195, 42)"],
        [0.66, "rgb(252, 189, 40)"],
        [0.67, "rgb(255, 183, 39)"],
        [0.68, "rgb(255, 177, 37)"],
        [0.69, "rgb(255, 171, 36)"],
        [0.7, "rgb(255, 164, 35)"],
        [0.71, "rgb(255, 158, 34)"],
        [0.72, "rgb(255, 151, 33)"],
        [0.73, "rgb(255, 144, 32)"],
        [0.74, "rgb(255, 137, 30)"],
        [0.75, "rgb(255, 130, 29)"],
        [0.76, "rgb(255, 123, 28)"],
        [0.77, "rgb(255, 116, 27)"],
        [0.78, "rgb(253, 109, 26)"],
        [0.79, "rgb(250, 102, 25)"],
        [0.8, "rgb(246, 95, 24)"],
        [0.81, "rgb(241, 88, 22)"],
        [0.82, "rgb(236, 81, 21)"],
        [0.83, "rgb(231, 74, 19)"],
        [0.84, "rgb(225, 68, 18)"],
        [0.85, "rgb(219, 61, 17)"],
        [0.86, "rgb(213, 55, 15)"],
        [0.87, "rgb(206, 49, 13)"],
        [0.88, "rgb(199, 44, 12)"],
        [0.89, "rgb(193, 38, 10)"],
        [0.9, "rgb(186, 34, 8)"],
        [0.91, "rgb(180, 29, 7)"],
        [0.92, "rgb(173, 25, 5)"],
        [0.93, "rgb(167, 21, 3)"],
        [0.94, "rgb(162, 18, 2)"],
        [0.95, "rgb(157, 16, 0)"],
        [0.96, "rgb(152, 14, 0)"],
        [0.97, "rgb(149, 12, 0)"],
        [0.98, "rgb(146, 12, 0)"],
        [0.99, "rgb(144, 12, 0)"],
        [1, "rgb(144, 12, 0)"],
      ],
    },
  ],
    xAxis: {
      lineWidth: 1,
      lineColor: "#cccccc",
      tickWidth: 1,
      tickColor: "#cccccc",
      labels: {
        style: {
          fontSize: "12px",
        },
      },
    },

    yAxis: [
      {
        className: "highcharts-color-0",
        gridLineWidth: 0,
        gridLineColor: "red",
        labels: {
          style: {
            fontSize: "14px",
          },
        },
        range: null,
        height: '75%',
        resize: {
          enabled: true
        },
      },
      {
        className: "highcharts-color-1",
        type: "linear",
        gridLineWidth: 0,
        gridLineColor: "red",
        labels: {
          style: {
            fontSize: "14px",
          },
        },
        lastPrice: true,
        range: null,
        top: '76%',
        height: '24%',
      },
    ],

    legend: {
      enabled: true,
      floating: true,
      symbolHeight: 335,
      navigation: {
        enabled: false,
      },
      itemStyle: {
        color: "#666",
        fontSize: "10px",
      },
      itemHoverStyle: {
        color: "#222",
      },
    },
    stockTools: {
      gui: {
        enabled: true,
      },
    },
    scrollbar: {
      enabled: false,
    },
    rangeSelector: {
      inputEnabled: false,
      selected: 4,
    },
    credits: {
      enabled: false,
    },
    exporting: {
      buttons: {
        contextButton: {
          enabled: false,
        },
        customButton: {
          text: "Lin",
          height: 22,
          width: 46,
          buttonSpacing: 5,
          onclick: function () {
            const index = this.exportSVGElements.findIndex(
              (s) => s && s.text.textStr === "Lin"
            );
            const other = this.exportSVGElements.findIndex(
              (s) => s && s.text.textStr === "Log"
            );
            this.exportSVGElements[index].setState(2);
            this.exportSVGElements[other].setState(0);
            this.yAxis.forEach((_, i) => {
              this.yAxis[i].update({ type: "linear" });
            });
          },
        },
        customButton2: {
          text: "Log",
          height: 22,
          onclick: function () {
            const index = this.exportSVGElements.findIndex(
              (s) => s && s.text.textStr === "Log"
            );
            const other = this.exportSVGElements.findIndex(
              (s) => s && s.text.textStr === "Lin"
            );
            this.exportSVGElements[index].setState(2);
            this.exportSVGElements[other].setState(0);
            this.yAxis.forEach((_, i) => {
              this.yAxis[i].update({ type: "logarithmic" });
            });
          },
          buttonSpacing: 5,
        },
        // zscore: {
        //   height: 20,
        //   buttonSpacing: 10,
        //   text: "Z-Score ▾",
        //   menuItems: [
        //     {
        //       text: "Ativar",
        //     },
        //     {
        //       text: "Desativar",
        //     },
        //   ],
        // },
        sma: {
          text: "Médias Moveis ▾",
          height: 20,
          buttonSpacing: 5,
          menuItems: [
            {
              text: "Desativar",
              onclick: function () {
                try {
                  const smaIndex = this.series.findIndex((s) => s.type === "sma");
                  if (smaIndex > -1)
                    this.series[smaIndex].update({
                      visible: false,
                    });
                } catch (error) {
                  return error
                } 
              },
            },
            {
              text: "SMA 7",
              onclick: function () {
                try {
                  const smaIndex = this.series.findIndex((s) => s.type === "sma");
                  this.series[smaIndex].update({
                    type: "sma",
                    linkedTo: "indicator",
                    visible: true,
                    params: {
                      period: 7,
                    },
                  });
                } catch (error) {
                  return error
                }
              },
            },
            {
              text: "SMA 30",
              onclick: function () {
                try {
                  const smaIndex = this.series.findIndex((s) => s.type === "sma");
                  this.series[smaIndex].update({
                    type: "sma",
                    linkedTo: "indicator",
                    visible: true,
                    params: {
                      period: 30,
                    },
                  });
                } catch (error) {
                  return error
                }
              },
            },
          ],
        },
        toggle: {
          text: "Período ▾",
          height: 20,

          theme: {
            "stroke-width": 0.5,
            stroke: "#000000",
            r: 2,
          },
          menuItems: [
            {
              text: "1M",
              onclick: function () {
                this.rangeSelector.clickButton(0, true);
              },
            },
            {
              text: "3M",
              onclick: function () {
                this.rangeSelector.clickButton(1, true);
              },
            },
            {
              text: "6M",
              onclick: function () {
                this.rangeSelector.clickButton(2, true);
              },
            },
            {
              text: "YTD",
              onclick: function () {
                this.rangeSelector.clickButton(3, true);
              },
            },
            {
              text: "1Y",
              onclick: function () {
                this.rangeSelector.clickButton(4, true);
              },
            },
            {
              text: "All",
              onclick: function () {
                this.rangeSelector.clickButton(5, true);
              },
            },
          ],
        },
      },
    },
  };

  return defaultOptions;
};

const scatterChart = {
  chart: {
    type: 'scatter',
    zoomType: 'xy',
  },
  boost: {
    useGPUTranslations: true,
    usePreAllocated: true
  },
  title: {
    text: ''
  },
  subtitle: {
    text: ''
  },
  xAxis: [{
    title: {
      enabled: true,
      text: 'Raridade (1 = mais raro)',
      style:{
        fontSize: "20px"
      }
    },
    startOnTick: true,
    endOnTick: true,
    showLastLabel: true
  }],
  yAxis: [{
    title: {
      enabled: true,
      text: 'List Price(Eth)',
    },
  }],
  legend: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    series: {
      turboThreshold: 11000,
    },
    scatter: {
      pointStart: 0,
      stickyTracking: false,
    }
  },
  tooltip: {
    stickOnContact: true,
    useHTML: true,
    hideDelay: 1000,
    style: {
      pointerEvents: 'auto',
      color: "rgba(255, 255, 255, 0.938)"
    },
    formatter: function() {
      let points = `Raridade ${this.x} / ${this.y} ETH`;
      let link = `<a href='${this.point.z}'>Link</a>`;
      let total = `<div className="collections-tooltip">${this.point.v}<br>${points}<br>${link}<br><img src=${this.point.u} height="82" width="100%" object-fit="contain" /></div>`;
      return total;
    },
  },
  series: [],
  exporting: {
    buttons: {
      contextButton: {
        enabled: false,
      },
      customButton2: {
          text: 'Log',
          width: 46,
          buttonSpacing: 5,
          cursor: "pointer",
          onclick: function() {
            this.yAxis[0].update({
              type: 'logarithmic'
            });
          }
      },
      customButton: {
        text: 'Lin',
        width: 46,
        buttonSpacing: 5,
        cursor: "pointer",
        onclick: function() {
          this.yAxis[0].update({
              type: 'linear'
          });
        }
      },
    },
  },
}

var data = [];

const histoChartOptions = {
  title: {
    text: '',
    style: {
      fontSize: "23px",
      fontWeight: 700,
      color: "#ffffff",
      fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif"
    },
    align: "left",
    y: 20,
    margin: 20
  },

  xAxis: {},

  yAxis: {},
  
  plotOptions: {
    series: {
      pointWidth: 25,
    }
  },

  series: [
    {
      name: 'Days Since',
      type: 'histogram',
      color: "#FC9F35",
      data
    }
  ],

  exporting: {
    buttons: {
      contextButton: {
        enabled: false,
      },
    }
  }
}

export { 
  casasciusChartOptions,
  defaultChartOptions,
  histoChartOptions,
  scatterChart 
};