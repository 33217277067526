const collections = [
    { collectionName: "cryptopunks" },
    { collectionName: "0n1-force" },
    { collectionLocation: "./animetas.json", collectionName: "animetas" },
    { collectionLocation: "./ape-gang.json", collectionName: "ape-gang" },
    { collectionLocation: "./bastard-gan-punks-v2.json", collectionName: "bastard-gan-punks-v2" },
    { collectionLocation: "./bears-deluxe.json", collectionName: "bears-deluxe" },
    { collectionLocation: "./bored-ape-kennel-club.json", collectionName: "bored-ape-kennel-club" },
    { collectionLocation: "./boredapeyachtclub.json", collectionName: "boredapeyachtclub" },
    { collectionLocation: "./collectvox.json", collectionName: "collectvox" },
    { collectionLocation: "./cool-cats-nft.json", collectionName: "cool-cats-nft" },
    { collectionLocation: "./cryptoadz-by-gremplin.json", collectionName: "cryptoadz-by-gremplin" },
    { collectionLocation: "./cryptopunks.json", collectionName: "cryptopunks" },
    { collectionLocation: "./cupcatsofficial.json", collectionName: "cupcatsofficial" },
    { collectionLocation: "./cyberkongz-vx.json", collectionName: "cyberkongz-vx" },
    { collectionLocation: "./cyberkongz.json", collectionName: "cyberkongz" },
    { collectionLocation: "./deadfellaz.json", collectionName: "deadfellaz" },
    { collectionLocation: "./fvck-crystal.json", collectionName: "fvck-crystal" },
    { collectionLocation: "./g-evols.json", collectionName: "g-evols" },
    { collectionLocation: "./galacticapes.json", collectionName: "galacticapes" },
    { collectionLocation: "./guttercatgang.json", collectionName: "guttercatgang" },
    { collectionLocation: "./hashmasks.json", collectionName: "hashmasks" },
    { collectionLocation: "./lazy-lions.json", collectionName: "lazy-lions" },
    { collectionLocation: "./lootproject.json", collectionName: "lootproject" },
    { collectionLocation: "./meebits.json", collectionName: "meebits" },
    { collectionLocation: "./meebits.json", collectionName: "mekaverse" },
    { collectionLocation: "./metasaurs-nft.json", collectionName: "metasaurs-nft" },
    { collectionLocation: "./peaceful-groupies.json", collectionName: "peaceful-groupies" },
    { collectionLocation: "./pudgypenguins.json", collectionName: "pudgypenguins" },
    { collectionLocation: "./robotos-official.json", collectionName: "robotos-official" },
    { collectionLocation: "./rumble-kong-league.json", collectionName: "rumble-kong-league" },
    { collectionLocation: "./space-punks-club.json", collectionName: "space-punks-club" },
    { collectionLocation: "./stoner-cats-official.json", collectionName: "stoner-cats-official" },
    { collectionLocation: "./supducks.json", collectionName: "supducks" },
    { collectionLocation: "./the-doge-pound.json", collectionName: "the-doge-pound" },
    { collectionLocation: "./the-humanoids.json", collectionName: "thehumanoids" },
    { collectionLocation: "./the-sevens-official.json", collectionName: "the-sevens-official" },
    { collectionLocation: "./thealcabones.json", collectionName: "thealcabones" },
    { collectionLocation: "./thecryptodads.json", collectionName: "thecryptodads" },
    { collectionLocation: "./thewickedcraniums.json", collectionName: "thewickedcraniums" },
    { collectionLocation: "./vogu.json", collectionName: "vogu" },
    { collectionLocation: "./wicked-ape-bone-club.json", collectionName: "wicked-ape-bone-club" },
    { collectionLocation: "./winterbears.json", collectionName: "winterbears" },
    { collectionLocation: "./world-of-women-nft.json", collectionName: "world-of-women-nft" },
]

export { collections }