import React from "react";
import MaskedInput from "react-text-mask";

const TextMaskCardExp = (props) => {
  const { inputRef = () => {}, mask, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-1]/, /[0-9]/, "/", /\d/, /\d/]}
      placeholderChar={"\u2000"}
    />
  );
};

export default TextMaskCardExp;
