import {
    GET_VIDEOS,
    CREATE_VIDEO,
    UPDATE_VIDEO,
    DELETE_VIDEO,
  } from '../actions/types';
    
    const initialState = {
      videos: [],
      video: null
    };
    
    export default function(state = initialState, action) {
      switch (action.type) {
        case GET_VIDEOS:
          return {
            ...state,
            videos: action.payload
          };
        case CREATE_VIDEO:
          return {
            ...state,
            video: action.payload
          };
        case UPDATE_VIDEO:
          return {
            ...state,
            video: action.payload
          };
        case DELETE_VIDEO:
          return {
            ...state,
            video: action.payload
          };
        default:
          return state;
      }
    }
    