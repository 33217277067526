import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/database";
import "firebase/auth";
import "firebase/storage";
import "firebase/analytics";

var firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJ_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MSG_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

firebase.initializeApp(firebaseConfig);

firebase.firestore().settings({ ignoreUndefinedProperties: true, merge: true });

const analytics = firebase.analytics();
const firestore = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();

if (
  process.env.NODE_ENV === "development" &&
  process.env.REACT_APP_FIREBASE_EMULATOR === "true"
) {
  auth.useEmulator("http://localhost:9099");
  firestore.useEmulator("localhost", 8080);
}

export { analytics, firestore, auth, storage };

export default firebase;
