import React from "react";

import IuguForm from "./Iugu";

const CardPayment = ({ selectedpaymentplan, form, goToStep, payments }) => {
  return (
    <>
      <IuguForm
        form={form}
        selectedpaymentplan={selectedpaymentplan}
        goToStep={goToStep}
      />
    </>
  );
};

export default CardPayment;
