import React from "react";

import iconPlan4Small from "../assets/IconeParadigma4Small.png";
import checkmark from "../assets/checkmark-outline-2.svg";
import coinLight from "../assets/coin-light.png";
import coinPro from "../assets/icon-moeda.svg";

export default {
  3: {
    title: "Paradigma Light",
    totalAnnualPrice: "598,80",
    annualPrice: "49,90",
    subtitle: "",
    description: "",
    smallIcon: "",
    itensCourse: (
      <>
        <li>
          <img src={checkmark} alt="checkmark" />
          <div>Todos nossos Mini-Cursos</div>
        </li>
        <li>
          <img src={checkmark} alt="checkmark" />
          <div>Boletim Semanal toda Quinta</div>
        </li>
        <li>
          <img src={checkmark} alt="checkmark" />
          <div>Manual de Uso do Bitcoin</div>
        </li>
        <li>
          <img src={checkmark} alt="checkmark" />
          <div>1 Ferramenta de Valuation</div>
        </li>
      </>
    ),
    icon: coinLight,
  },
  5: {
    title: "Paradigma Pro",
    totalAnnualPrice: "1438,80",
    annualPrice: "119,90",
    subtitle: (
      <>
        <i>Master kit</i> de inteligência
      </>
    ),
    description:
      "Envie a quantia exata. Faça um café. Aguarde confirmação por e-mail.",
    smallIcon: iconPlan4Small,
    itensCourse: (
      <>
        <li>
          <img src={checkmark} alt="checkmark" />
          <div>Carteiras simuladas</div>
        </li>
        <li>
          <img src={checkmark} alt="checkmark" />
          <div>+20 indicadores exclusivos</div>
        </li>
        <li>
          <img src={checkmark} alt="checkmark" />
          <div>Chat direto com analistas, 24/7</div>
        </li>
        <li>
          <img src={checkmark} alt="checkmark" />
          <div>Boletins, relatórios e cursos</div>
        </li>
      </>
    ),
    icon: coinPro,
  },
};
