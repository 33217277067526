import React, { lazy, Suspense } from "react";
import { getUserDetailsFromCookie } from "../../utils/getCookies";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import Collections from "../collections/Collections";
import PointProgress from "./PointProgress";

const AnaliseTecnica = lazy(() => import("../analise-tecnica/AnaliseTecnica"));
const Category = lazy(() => import("../feed/Category"));
const Feed = lazy(() => import("../feed/Feed"));
const FeedExpand = lazy(() => import("../feed/FeedExpand"));
const Fundamentos = lazy(() => import("../fundamentos/Fundamentos"));
const Indicadores = lazy(() => import("../indicadores/Indicadores"));
const Login = lazy(() => import("../login/Login"));
const Success = lazy(() => import("../login/Success"));
const Manual = lazy(() => import("../manual/Manual"));
const Modelos = lazy(() => import("../modelos/Modelos"));
const UserProfile = lazy(() => import("../user-profile/UserProfile"));
const Dashboard = lazy(() => import("./Dashboard"));
const Onboarding = lazy(() => import("../onboarding/Onboarding"));
const NotFound = lazy(() => import("./NotFound"));
const ErrorPage = lazy(() => import("./ErrorPage"));
const PrivateRoute = lazy(() => import("../private/PrivateRoute"));
const Trilhas = lazy(() => import("../trilhas/Trilhas"));
const DetailTrilha = lazy(() => import("../trilhas/DetailTrilha"));
const DetailVideo = lazy(() => import("../trilhas/DetailVideo"));
const WalletScan = lazy(() => import("../scan/Scan"));
const WalletScanDetail = lazy(() => import("../scan/ScanDetail"));

const SidebarFundamentos = lazy(() => import("../fundamentos/RightSidebar"));
const SidebarNFT = lazy(() => import("../collections/RightSidebar"));
const SidebarModelos = lazy(() => import("../modelos/RightSidebar"));
const SidebarIndicadores = lazy(() => import("../indicadores/RightSidebar"));
const SidebarFeed = lazy(() => import("../feed/RightSidebar"));
const SidebarFeedExpand = lazy(() => import("../feed/RightSidebarExpand"));
const SidebarFeedRank = lazy(() => import("../feed/RightSidebarRank"));
const SidebarTrilhas = lazy(() => import("../trilhas/RightSidebar"));

const Routes = ({ auth: authState, readingProgress }) => {
  const user = getUserDetailsFromCookie();

  return (
    <Router>
      <Suspense
        fallback={
          <div id="loadingPage" className="visible">
            <PointProgress />
          </div>
        }
      >
        <Switch>
          <Route
            path="/"
            exact
            component={() => (
              <Dashboard
                component={Feed}
                rightSidebar={SidebarFeedRank}
                readingProgress={readingProgress}
                user={user}
              />
            )}
          />
          <Route
            path="/feed"
            exact
            component={() => (
              <Dashboard
                component={Feed}
                rightSidebar={SidebarFeedRank}
                readingProgress={readingProgress}
                user={user}
              />
            )}
          />
          <Route
            path="/feed/:category"
            exact
            component={() => (
              <Dashboard
                component={Category}
                rightSidebar={SidebarFeed}
                user={user}
                readingProgress={readingProgress}
              />
            )}
            auth={authState}
          />
          <PrivateRoute
            path="/feed/post/view"
            exact
            component={() => (
              <Dashboard
                user={user}
                component={FeedExpand}
                rightSidebar={SidebarFeedExpand}
                readingProgress={readingProgress}
              />
            )}
          />
          <PrivateRoute
            path="/feed/post/:id"
            exact
            component={() => (
              <Dashboard
                user={user}
                component={FeedExpand}
                rightSidebar={SidebarFeedExpand}
                readingProgress={readingProgress}
              />
            )}
          />
          <PrivateRoute
            path="/carteiras"
            exact
            component={() => (
              <Dashboard
                component={Fundamentos}
                rightSidebar={SidebarFundamentos}
                readingProgress={readingProgress}
                user={user}
              />
            )}
          />
          <PrivateRoute
            path="/minhaconta"
            exact
            component={() => (
              <Dashboard
                component={UserProfile}
                // rightSidebar={user ? SidebarFeedRank : null}
                fullWidth
                dropDown="Fundamentos"
                user={user}
              />
            )}
          />
          <Route
            path="/modelos"
            exact
            component={() => (
              <Dashboard
                component={Modelos}
                rightSidebar={SidebarModelos}
                readingProgress={readingProgress}
                user={user}
              />
            )}
          />
          <Route
            path="/indicadores"
            exact
            component={() => (
              <Dashboard
                component={Indicadores}
                rightSidebar={SidebarIndicadores}
                readingProgress={readingProgress}
                user={user}
              />
            )}
          />
          <Route
            path="/experimentar"
            exact
            component={() => <Dashboard component={Login} user={user} />}
          />
          <Route
            path="/login"
            exact
            component={() => <Dashboard component={Login} user={user} />}
          />
          <Route
            path="/success"
            exact
            component={() => <Dashboard component={Success} user={user} />}
          />
          <Route
            path="/manual"
            exact
            component={() => (
              <Dashboard
                component={Manual}
                dropDown="Fundamentos"
                pathActive="/manual"
                user={user}
                fullWidth
              />
            )}
          />
          <Route
            path="/analise-tecnica"
            exact
            component={() => (
              <Dashboard component={AnaliseTecnica} user={user} />
            )}
          />
          <Route
            path="/onboarding"
            exact
            component={() => <Dashboard component={Onboarding} user={user} />}
          />
          <PrivateRoute
            path="/NFT"
            exact
            component={() => (
              <Dashboard
                component={Collections}
                rightSidebar={SidebarNFT}
                user={user}
              />
            )}
          />
          <PrivateRoute
            path="/trilhas"
            exact
            component={() => (
              <Dashboard
                component={Trilhas}
                rightSidebar={SidebarTrilhas}
                user={user}
                noBorder
              />
            )}
          />
          <PrivateRoute
            path="/trilhas/:trilhaId"
            exact
            component={() => (
              <Dashboard component={DetailTrilha} user={user} fullWidth />
            )}
          />
          <PrivateRoute
            path="/trilhas/:trilhaId/:moduleId/:videoId"
            exact
            component={() => (
              <Dashboard component={DetailVideo} user={user} fullWidth />
            )}
          />
          <PrivateRoute
            path="/scan"
            exact
            component={() => <Dashboard component={WalletScan} user={user} />}
          />
          <PrivateRoute
            path="/scan/:scanId"
            exact
            component={() => (
              <Dashboard component={WalletScanDetail} user={user} />
            )}
          />
          {/* <PrivateRoute
            path="/subscription"
            exact
            component={() => (
              <Dashboard
                component={Subscription}
                fullWidth
                dropDown="Fundamentos"
                user={user}
              />
            )}
          /> */}
          <Route
            path="/error-page"
            exact
            component={() => <Dashboard component={ErrorPage} fullWidth />}
          />
          <Route
            component={() => <Dashboard component={NotFound} pathActive="/" />}
          />
        </Switch>
      </Suspense>
    </Router>
  );
};

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  authLoaded: state.firebase.auth.isLoaded,
  readingProgress: state.posts.readingProgress,
});

export default connect(mapStateToProps)(Routes);
