export const GET_POSTS = "GET_POSTS";
export const GET_POST = "GET_POST";
export const GET_TRENDS_DATA = "GET_TRENDS_DATA";
export const UPDATE_READING_PROGRESS = "UPDATE_READING_PROGRESS";
export const GET_POSTS_ERROR = "GET_POSTS_ERROR";

export const GET_COUPON_CODES = "GET_COUPON_CODES";

export const SET_PRESENCE = "SET_PRESENCE";
export const SET_MESSAGES = "SET_MESSAGES";
export const SET_PINNED_MESSAGES = "SET_PINNED_MESSAGES";
export const SET_SEARCHED_MESSAGES = "SET_SEARCHED_MESSAGES";
export const SET_SEARCH_QUERY = "SET_SEARCH_QUERY";
export const SET_AUTHORED_MESSAGES = "SET_AUTHORED_MESSAGES";
export const SET_MESSAGES_PRO = "SET_MESSAGES_PRO";
export const SET_MORE_MESSAGES = "SET_MORE_MESSAGES";
export const ADD_MESSAGE = "ADD_MESSAGE";
export const UPDATE_MESSAGE = "UPDATE_MESSAGE";
export const SET_UNREAD_NOTIFICATIONS = "SET_UNREAD_NOTIFICATIONS";

export const GET_GRAFICOS = "GET_GRAFICOS";
export const GET_GRAFICOS_POSTS = "GET_GRAFICOS_POSTS";

export const GET_TECHNICAL_ANALYSIS = "GET_TECHNICAL_ANALYSIS";

export const GET_FUNDAMENTOS = "GET_FUNDAMENTOS";
export const GET_FUNDAMENTOS_ERROR = "GET_FUNDAMENTOS_ERROR";
export const GET_FUNDAMENTOS_POSTS = "GET_FUNDAMENTOS_POSTS";
export const SET_CARTEIRA = "SET_CARTEIRA";

export const GET_ENERGY = "GET_ENERGY";
export const GET_S2F = "GET_S2F";
export const GET_METCALFE = "GET_METCALFE";
export const GET_MODEL_DATA = "GET_MODEL_DATA";
export const GET_MODEL_ERROR = "GET_MODEL_ERROR";

export const GET_GOOGLE_TRENDS = "GET_GOOGLE_TRENDS";

export const SET_REFRESH = "SET_REFRESH";
export const SET_USER = "SET_USER";
export const SET_READ_HISTORY = "SET_READ_HISTORY";
export const LOGOUT = "LOGOUT";
export const SET_SEARCHKEYWORD = "SET_SEARCHKEYWORD";

export const GET_CURATED_PROFILES = "GET_CURATED_PROFILES";

export const GET_SECTION_ONE = "GET_SECTION_ONE";
export const GET_SECTION_TWO = "GET_SECTION_TWO";
export const GET_SECTION_THREE = "GET_SECTION_THREE";
export const GET_SECTION_FOUR = "GET_SECTION_FOUR";
export const GET_SECTION_FIVE = "GET_SECTION_FIVE";
export const GET_SECTION_SIX = "GET_SECTION_SIX";

export const GET_VIDEOS = "GET_VIDEOS";
export const UPDATE_VIDEO = "UPDATE_VIDEO";
export const DELETE_VIDEO = "DELETE_VIDEO";
export const CREATE_VIDEO = "CREATE_VIDEO";
export const GET_VIDEO = "GET_VIDEO";

export const FETCH_COLLECTIONS = "FETCH_COLLECTIONS";
export const FETCH_COLLECTION_SCORES = "FETCH_COLLECTION_SCORES";
export const FAILED_TO_FETCH_COLLECTIONS = "FAILED_TO_FETCH_COLLECTIONS";

export const GET_SCAN_NFT = "GET_SCAN_NFT";

export const SET_COUPON = "SET_COUPON";
export const CREATE_NEW_COUPON = "CREATE_NEW_COUPON";
export const COUPON_CREATION_FAILED = "COUPON_CREATION_FAILED";
export const SUBSCRIPTION_CANCELLING_ERROR = "SUBSCRIPTION_CANCELLING_ERROR";
export const SUBSCRIPTION_CANCELLED = "SUBSCRIPTION_CANCELLED";
export const SUBSCRIPTION_UPDATING_ERROR = "SUBSCRIPTION_UPDATING_ERROR";
export const SUBSCRIPTION_UPDATED = "SUBSCRIPTION_UPDATED";

export const GET_EMOJIS = "GET_EMOJIS";
export const CREATE_EMOJI = "CREATE_EMOJI";
export const EMOJI_ERROR = "EMOJI_ERROR";

export const CREATE_COMMENT_LIKES = "CREATE_COMMENT_LIKES";
export const CREATE_COMMENT = "CREATE_COMMENT";
export const GET_COMMENT = "GET_COMMENT";
export const COMMENT_ERROR = "COMMENT_ERROR";

export const SET_INSTALLMENTS = "SET_INSTALLMENTS";
