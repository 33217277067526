import { Grid, Switch } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import HC_exporting from "highcharts/modules/exporting";
import boost from "highcharts/modules/boost";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import { connect } from 'react-redux';
import "../../css/collections.scss";
import { scatterChart } from '../../data/chart';
import TextField from '@material-ui/core/TextField';
import { fetchCollectionScores } from '../../store/actions/collections';
import { collections } from '../../data/collections';
import { getUserDetailsFromCookie } from '../../utils/getCookies';
import Paywall from '../payment/Paywall';
import PointProgress from '../layouts/PointProgress';
import { useGlobalContext } from '../../context';

HC_exporting(Highcharts);
boost(Highcharts);

const Collections = ({ fetchCollectionScores,scores }) => {
  const user = getUserDetailsFromCookie();
  const chartComponent = useRef(null);
  const [chartOptions, setChartOptions] = useState(scatterChart);
  const [loading, setLoading] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isToggled, setIsToggled] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const {
    currentCollection,
    setCurrentCollection,
    foundCollectionsAfterSearch,
    setFoundCollectionsAfterSearch,
    selectCollectionSidebar,
  } = useGlobalContext();

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  }

  const selectCollection = async (name) => {
    setCurrentCollection(name);
    setIsDropdownOpen(false);
    setLoading(true)
    await fetchCollectionScores({ collectionName: name  });
    setLoading(false);
  }

  useEffect(() => {
    selectCollection(selectCollectionSidebar);
    // eslint-disable-next-line
  }, [selectCollectionSidebar]);

  useEffect(() => {
    if (!isToggled && scores.listEqualToSalePrice){
      setChartOptions({
        ...scatterChart,
        yAxis: {
          ...scatterChart.yAxis[0],
          title: {
            ...scatterChart.yAxis[0].title,
            text: 'Última venda (ETH)'
          }
        },
        series: [
          {
            type: "scatter",
            name: 'Rarity vs Last Sale Price(Eth)',
            color: "#27AE60",
            data: scores.listHigherThanSalePrice
          },
          {
            type: "scatter",
            name: 'Rarity vs Listing Price(Eth)',
            color: "#E74C3C",
            data: scores.listLowerThanSalePrice
          },
          {
            type: "scatter",
            name: 'Rarity vs Listing Price(Eth)',
            color: "#9B59B6",
            data: scores.listEqualToSalePrice
          }
        ]
      })
    }

    if (isToggled && scores.listEqualToSalePrice){
      setChartOptions({
        ...scatterChart,
        yAxis: [{
          ...scatterChart.yAxis[0],
          title: {
            ...scatterChart.yAxis[0].title,
            text: 'Listado a (ETH)'
          }
        }],
        series: [
          {
            type: "scatter",
            name: 'Rarity vs Last Sale Price(Eth)',
            color: "#27AE60",
            data: scores.listHigherThanSalePrice.map((score) => {
              return {
                ...score,
                y: score.t
              }
            })
          },
          {
            type: "scatter",
            name: 'Rarity vs Listing Price(Eth)',
            color: "#E74C3C",
            data: scores.listLowerThanSalePrice.map((score) => {
              return {
                ...score,
                y: score.t
              }
            })
          },
          {
            type: "scatter",
            name: 'Rarity vs Listing Price(Eth)',
            color: "#9B59B6",
            data: scores.listEqualToSalePrice.map((score) => {
              return {
                ...score,
                y: score.t
              }
            })
          }
        ]
      })
    }
  }, [isToggled, scores])

  useEffect(() => {
    fetchCollectionScores({ collectionName: currentCollection });
  }, [currentCollection, fetchCollectionScores])

  useEffect(() => {
    if (searchQuery !== ""){
      const searchedResult = [];
      collections.forEach((collection) => {
          if (collection.collectionName.toLowerCase().startsWith(searchQuery.toLocaleLowerCase().slice(0, Math.max(collection.collectionName.length - 1, 1)))){
            searchedResult.push(collection);
          }
      })
      setFoundCollectionsAfterSearch(searchedResult)
    }

    if (searchQuery === ""){ 
      setFoundCollectionsAfterSearch([])
    }
    // eslint-disable-next-line
  }, [searchQuery])

  useEffect(() => {
    if (scores.listEqualToSalePrice){ 
      setChartOptions({
        ...scatterChart,
        chart: {
          ...scatterChart.chart,
          height: '60%'
        },
        yAxis: [{
          ...scatterChart.yAxis[0],
          title: {
            ...scatterChart.yAxis[0].title,
            text: 'Last Sale Price(Eth)'
          }
        }],
        series: [
          {
            type: "scatter",
            name: 'Rarity vs Last Sale Price(Eth)',
            color: "#27AE60",
            data: scores.listHigherThanSalePrice
          },
          {
            type: "scatter",
            name: 'Rarity vs Listing Price(Eth)',
            color: "#E74C3C",
            data: scores.listLowerThanSalePrice
          },
          {
            type: "scatter",
            name: 'Rarity vs Listing Price(Eth)',
            color: "#9B59B6",
            data: scores.listEqualToSalePrice
          }
        ]
      })
    } 
  }, [scores])

  const handleChange = async () => {
    setIsToggled(!isToggled);
    setFoundCollectionsAfterSearch([]);
  }

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  }

  if (user && user.pplan < 4){
    return (
      <div className="margin-top-paywall-15">
        <Paywall />
      </div>
    )
  }

  return (
    <div className="collections-container">
      <div className="collections-container-holder">
        <Grid className="collections-container-grid" container direction="row" spacing={4}>
          <Grid item>
            <div className="collection-dropdown">
              <div className="colletion-dropdown-upper" onClick={toggleDropdown}>
                <Grid container direction="row" alignItems="center">
                  <Grid item xs>
                    <span>{currentCollection ? currentCollection : "Select Collection"}</span>
                  </Grid>

                  <Grid item>
                      {isDropdownOpen ? <ArrowDropUp /> : <ArrowDropDown />}
                  </Grid>
                </Grid>
              </div>  

              {isDropdownOpen && collections && collections.length > 1? <div className="colletion-dropdown-lower">
                <div className="colletion-dropdown-lower-search-container">
                  <TextField
                    id="outlined-basic"
                    label="Busque..."
                    variant="outlined"
                    value={searchQuery}
                    onChange={handleSearch}
                    className="inputOutlined"
                  />
                </div>
                { foundCollectionsAfterSearch.length > 0 ? foundCollectionsAfterSearch.map((collection) => {
                  return (
                    <div>
                      
                      <div className="collection-nft-item" onClick={() => selectCollection(collection.collectionName)}>
                        <span>{collection.collectionName}</span>
                      </div>
                    </div>
                    
                  )
                })  : collections.slice(0, 10).map((collection) => {
                  return (
                    <div>
                      
                      <div className="collection-nft-item" onClick={() => selectCollection(collection.collectionName)}>
                        <span>{collection.collectionName}</span>
                      </div>
                    </div>
                    
                  )
                }) }
              </div> : null }
            </div>
          </Grid>

          <Grid item>
            <Grid container direction="row" alignItems="center">
              <Grid style={{ marginLeft: '25px', marginTop: 7 }} item>
                <Switch color="secondary" onChange={handleChange} checked={isToggled} />
              </Grid>

              <Grid style={{ marginTop: 7 }} item>
                <div style={{fontSize: 16}}><span className={isToggled ? "drop-in-active" : "drop-active"}>Última Venda</span> <span className="drop-active">/</span> <span className={isToggled ? "drop-active" : "drop-in-active"}>Preço de Listagem</span></div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
                
        <div style={{marginTop: 20}} className="collections-file">
          {!scores.listEqualToSalePrice || loading ? <div style={{width: '100%'}}><PointProgress /></div> : <HighchartsReact //item="internal"
            highcharts={Highcharts}
            options={chartOptions}
            allowChartUpdate={true}
            ref={chartComponent}
          />}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  collections: state.collections.collections,
  scores: state.collections.scores,
});

export default connect(mapStateToProps, {fetchCollectionScores})(Collections);
