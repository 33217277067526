import { 
    FETCH_COLLECTIONS,
    FETCH_COLLECTION_SCORES,
} from "./types";
import axios from 'axios';

export const fetchCollections = (payload) => async (dispatch) => {
    try {
        const data = await axios.get(`${process.env.REACT_APP_API}/collections`);
        const collections = data.data.collections ? data.data.collections : data.data.data.collections
        const collectionsNotToQuery = [
            'axie', 'cool-cats-nft', 'cryptopunks', '0n1-force',
            'cyberkongz-vx', 'cyberkongz', 'hashmasks', 'lazy-lions',
            'lootproject', 'bored-ape-kennel-club', 'boredapeyachtclub'
        ]
        const collectionsToRender = collections.filter((collection) => collectionsNotToQuery.includes(collection.name))
        
        return dispatch({
          type: FETCH_COLLECTIONS,
          payload: collectionsToRender
        });
    } catch (error) {
        return error;
    }
};

export const fetchCollectionScores = ({ collectionName }) => async (dispatch) => {
    try {
        const data = await axios.post(`${process.env.REACT_APP_API}/collection-scores`, { collectionName });
        return dispatch({
          type: FETCH_COLLECTION_SCORES,
          payload: data.data.scores ? data.data.scores : data.data.data.scores
        });
    } catch (error) {
        return error;
    }
};
