import React, { useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import {
  authenticateUser,
  setUser,
  refreshToken,
} from "../../../store/actions/authActions";
import { getUserDetailsFromCookie } from "../../../utils/getCookies";
import payCred from "../../../assets/img-payCred.png";
import arrowBack from "../../../assets/arrow-back-outline-1-1.svg";
import loadingGif from "../../../assets/loading-button.gif";
import Message from "./Message";

import { validCreditCardNumber } from "../../../utils/validateCard";
import cpfValidator from "../../../utils/validar-cpf-cnpj";
import pplansData from "../../../utils/pplansData";
import { states } from "../../../utils/states";
import TextMaskCEP from "../../../utils/TextMaskCEP";
import TextMaskCardNumber from "../../../utils/TextMaskCardNumber";
import TextMaskCardCPF from "../../../utils/TextMaskCardCPF";
import TextMaskCardExp from "../../../utils/TextMaskCardExp";
import TextMaskCardCvc from "../../../utils/TextMaskCardCvc";

window.Iugu.setAccountID("8B572DEA6B52461C97CEF8DDA90B5C50");
// window.Iugu.setTestMode(true);

const IuguForm = (props) => {
  const { selectedpaymentplan: pplan, form, goToStep } = props;
  console.info(11, props);
  const user = getUserDetailsFromCookie();
  const initialValue = {
    neighborhood: "",
    street: "",
    streetNumber: "",
    postal_code: "",
    state: "",
    country: "BR",
    city: "",
    documentNumber: "",
    email: "",
    pplan,
    paymentMethod: "credit_card",
    cardToken: "",
    installments: 1,
  };

  const [values, setValues] = useState(initialValue);
  const [cpfNumber, setCpfNumber] = useState("");
  const [cardExp, setCardExp] = useState("");
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [text, setText] = useState("");
  const [status, setStatus] = useState(null); 
  const [loading, setLoading] = useState("");
  const [cpfNumNotValid, setCpfNumNotValid] = useState(false);
  const [cardNumberNotValid, setCardNumberNotValid] = useState(false);
  const [expMonthAndYearNotValid, setExpMonthAndYearNotValid] = useState(false);
  const [installments, setInstallments] = useState(1);
  const pplanData = pplansData[pplan];
  const [cepNotValid, setCepNotValid] = useState(false);
  const [price, setPrice] = useState(
    parseFloat(pplanData.totalAnnualPrice.replace(",", ".")),
  );
  const [cardToken, setCardToken] = useState("");

  const onChange = (ev) => {
    const { name, value } = ev.target;
    if (name === "card-exp") {
      setCardExp(value);
      setExpMonthAndYearNotValid(false);
    }
    if (name === "card-number") {
      const isValidCard = validCreditCardNumber(value);
      setCardNumberNotValid(!isValidCard);
    }
    if (name === "documentNumber") {
      setCpfNumber(value);
      if (
        cpfValidator.validCnpj(value.replace(/(\.|-|\/)/g, "").trim()) ||
        cpfValidator.validCpf(value.replace(/(\.|-|\/)/g, "").trim())
      ) {
        setCpfNumNotValid(false);
      } else {
        setCpfNumNotValid(true);
      }
    }
    setValues({ ...values, [name]: value });
  };

  const fillAddress = (data) => {
    setValues({
      ...values,
      street: data.logradouro,
      state: data.uf,
      city: data.localidade,
      neighborhood: data.bairro,
    });
  };

  const onBlurCep = (ev) => {
    setLoading(true);
    const { value } = ev.target;
    const cep = value?.replace(/[^0-9]/g, "");
    if (cep?.length !== 8) {
      setLoading(false);
      return;
    }

    axios({
      url: `${process.env.REACT_APP_GOLANG_API}/cep/${cep}`,
      method: "GET",
    })
      .then((res) => {
        fillAddress(res.data);
        setLoading(false);
        if (res.data.cep) {
        } else {
          setCepNotValid(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const toCent = (amount) => {
    const str = amount.replace(",", ".");
    return str.length < 3 || str[str.length - 3] === "."
      ? Number(str.replace(".", ""))
      : Number(str.replace(".", "")) * 100;
  };

  const handleSubmit = async (event) => {
    var tokenResponseHandler = async function (data) {
      if (data.errors) {
        setStatus("error");
        setText("Dados do cartão invalidos");
      } else {
        await setCardToken(data.id);
        setStatus("warning");
        setText("Seu cartão foi validado, aperte em Pagar para continuar.");
      }
    };
    setSubmitDisabled(true);
    setLoading(true);
    await window.Iugu.createPaymentToken(event.target, tokenResponseHandler);

    event.preventDefault();
    const authURL = `${process.env.REACT_APP_GOLANG_API}/auth/signup`;
    const payURL = `${process.env.REACT_APP_GOLANG_API}/iugu/subscriptions`;

    if (!cardToken) {
      setLoading(false);
      setSubmitDisabled(false);
      return false;
    }

    let payloadPayment = {
      customer: {
        address: {
          line1: values.street,
          number: values.streetNumber,
          postalCode: values.postal_code,
          state: values.state,
          city: values.city,
        },
        documentNumber: values.documentNumber.replace(/(\.|-|\/)/g, "").trim(),
        email: form.email,
        name: values.name,
      },
      paymentMethod: "credit_card",
      cardToken,
      pplan,
      installments: installments,
      planPrice: toCent(pplanData.totalAnnualPrice),
    };
    setLoading(true);

    let authRes, token;
    try {
      authRes = await axios.post(authURL, {
        email: user && user.email ? user.email : form.email,
      });
      setUser(authRes.data.user, false);
      token = authRes.data.token;

      if (form.coupon) {
        console.log("form.coupon", form.coupon);
        payloadPayment = {
          ...payloadPayment,
          coupon: form.coupon.id,
          discount: form.discountPercentage,
        };
      }

      try {
        token = authRes.data.token;
        const paymentRes = await axios.post(payURL, payloadPayment, {
          headers: { Authorization: `Bearer ${token}` },
        });
        await refreshToken(token);
        if (paymentRes) {
          setLoading(false);
          setSubmitDisabled(false);
          setStatus("success");
          setText("Assinatura realizada com sucesso!");
          setTimeout(() => {
            window.location.href = window.origin + `/minhaconta`;
          }, 1500);
        }
      } catch (err) {
        setLoading(false);
        setSubmitDisabled(false);

        const { action, data, provider } = err.response?.data || {};
        if (data) {
          const jsonString = data.substring(data.indexOf('{'));
          const { message } = JSON.parse(jsonString);
          setStatus("error")
          setText(message);
        }
        console.log(`action: ${action}, data: ${data}, provider: ${provider}`);
      }
    } catch (err) {
      setLoading(false);
      if (err.response) {
        setStatus("error");
        setText(err.response.data.data);
      }

      if (!err.response) {
        setStatus("error");
        setText("Preencha todos os campos obrigatórios!");
      }
    }
  };

  const aplicateDiscount = (value) => {
    let totalPrice = parseFloat(pplanData.totalAnnualPrice.replace(",", "."));
    const priceDiscount =
      totalPrice - (totalPrice * form.discountPercentage) / 100;
    const oldPrice = form.discountPercentage ? priceDiscount : totalPrice;
    return (oldPrice / installments).toFixed(2);
  };

  const handleChange = async (event) => {
    setCpfNumber("");
    setText("");
    const currentInstallments = parseFloat(event.target.value);
    setInstallments(event.target.selectedIndex + 1);
    await setPrice(parseFloat(currentInstallments.toFixed(2)));
  };

  const installmentsOptions = (numberInstallment) => {
    let totalPrice = parseFloat(pplanData.totalAnnualPrice.replace(",", "."));
    const priceDiscount =
      totalPrice - (totalPrice * form.discountPercentage) / 100;
    const oldPrice = form.discountPercentage ? priceDiscount : totalPrice;
    const totalInstallment = [];
    for (let i = 1; i <= numberInstallment; i++) {
      totalInstallment.push(
        <option key={i} value={(oldPrice / i).toFixed(2)}>
          {`${i}x R$${(oldPrice / i).toFixed(2)}`}
        </option>,
      );
    }
    return totalInstallment;
  };

  return (
    <div className="data-creditCard">
      <img
        className="btn-prev"
        src={arrowBack}
        alt="arrow back"
        onClick={() => goToStep(1)}
        style={{ outline: "none" }}
      />
      <img src={payCred} alt="Pagamento" style={{ marginBottom: 20 }} />
      {text && (
        <Message 
          text={text} 
          status={status} 
        />
      )}
    
      <div className="form-wrapper-scroll">
        <form
          className="card-payment-scroll"
          autoComplete="off"
          id="card-payment"
          onSubmit={handleSubmit}
        >
          <div className="input-group">
            <label className="label-input-group">Parcelas</label>
            <select
              id="parcelas"
              name="parcelas"
              className="parcel-select"
              onChange={handleChange}
            >
              {installmentsOptions(12)}
            </select>
          </div>

          <div className="input-group padding-input-group">
            <label className="label-input-group">Número no cartão</label>
            <TextMaskCardNumber
              name="card-number"
              className="text-field w-input"
              data-pagarmecheckout-element="number"
              data-iugu="number"
              type="text"
              onChange={onChange}
              style={{
                color: cardNumberNotValid ? "red" : "#212",
              }}
            />
          </div>

          <div className="input-group padding-input-group">
            <label className="label-input-group">Nome no cartão</label>
            <input
              type="text"
              onChange={onChange}
              name="holder-name"
              className="text-field w-input"
              data-pagarmecheckout-element="number"
              data-iugu="full_name"
            />
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "2fr 2fr",
              columnGap: "8px",
            }}
          >
            <div className="input-group padding-input-group">
              <label className="label-input-group">Mês de vencimento</label>
              <TextMaskCardExp
                name="card-exp"
                className="text-field w-input"
                type="text"
                data-iugu="expiration"
                value={cardExp}
                onChange={onChange}
                style={{
                  color: expMonthAndYearNotValid ? "red" : "#212",
                }}
              />
            </div>
            <div className="input-group padding-input-group">
              <label className="label-input-group">CVC</label>
              <TextMaskCardCvc
                name="cvv"
                data-iugu="verification_value"
                data-pagarmecheckout-element="cvv"
                className="text-field w-input"
                type="text"
                onChange={onChange}
              />
            </div>
          </div>

          <div className="input-group padding-input-group">
            <label htmlFor="documentNumber" className="label-input-group">
              CPF (ou CNPJ)
            </label>
            <TextMaskCardCPF
              id="documentNumber"
              name="documentNumber"
              type="text"
              className="text-field w-input"
              style={{
                color: cpfNumNotValid ? "red" : "#212",
              }}
              placeholder="Número do documento"
              value={cpfNumber}
              onChange={installments > 1 ? onChange : onChange}
              data-name="Email 3"
            />
          </div>

          {
            <>
              <div className="input-group padding-input-group">
                <label className="label-input-group">Nome completo</label>
                <input
                  type="text"
                  name="name"
                  onChange={onChange}
                  placeholder="Nome e sobrenome"
                  className="text-field w-input"
                />
              </div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  columnGap: "8px",
                }}
              >
                <div className="input-group padding-input-group">
                  <label className="label-input-group">Código postal</label>
                  <TextMaskCEP
                    name="postal_code"
                    className="text-field w-input"
                    type="text"
                    onBlur={(ev) => onBlurCep(ev)}
                    onChange={onChange}
                    style={{
                      color: cepNotValid ? "red" : "#212",
                    }}
                  />
                </div>
                <div className="input-group padding-input-group">
                  <label className="label-input-group">Número</label>
                  <input
                    type="text"
                    name="streetNumber"
                    onChange={onChange}
                    placeholder="N° da rua"
                    className="text-field w-input"
                  />
                </div>
              </div>
              <div className="input-group padding-input-group">
                <label className="label-input-group">Endereço</label>
                <input
                  type="text"
                  name="street"
                  value={values.street}
                  onChange={onChange}
                  placeholder="Nome da rua"
                  className="text-field w-input"
                />
              </div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  columnGap: "8px",
                }}
              >
                <div className="input-group padding-input-group">
                  <label className="label-input-group">Estado</label>
                  <select
                    name="state"
                    onChange={onChange}
                    value={values.state}
                    className="parcel-select"
                  >
                    <option value="" disabled selected hidden></option>
                    {states.map((state, index) => (
                      <option key={index} value={state.value}>
                        {state.label}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="input-group padding-input-group">
                  <label className="label-input-group">Cidade</label>
                  <input
                    type="text"
                    name="city"
                    value={values.city}
                    onChange={onChange}
                    className="text-field w-input"
                  />
                </div>
              </div>
            </>
          }

          <div id="field_errors" style={{ color: "red" }}></div>

          <div>
            <button
              className="btn btn-primary btn-block cardPayment"
              type="submit"
              disabled={loading || submitDisabled}
            >
              {loading ? (
                <img style={{ width: "20px" }} src={loadingGif} alt="loading" />
              ) : (
                `Pagar ${installments}x R$ ${
                  form.discountPercentage
                    ? aplicateDiscount(price)
                    : price.toFixed(2)
                }`
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.firebase.auth,
});

export default connect(mapStateToProps, { authenticateUser, setUser })(
  IuguForm,
);
