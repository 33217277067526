import {
  GET_SECTION_ONE,
  GET_SECTION_TWO,
  GET_SECTION_THREE,
  GET_SECTION_FOUR,
  GET_SECTION_FIVE,
  GET_SECTION_SIX
} from "../actions/types";

const initialState = {
  sectionOne: {},
  sectionTwo: {},
  sectionThree: {},
  sectionFour: {},
  sectionFive: {},
  sectionSix: {}
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SECTION_ONE:
      return {
        ...state,
        sectionOne: action.payload
      };
    case GET_SECTION_TWO:
      return {
        ...state,
        sectionTwo: action.payload
      };
      case GET_SECTION_THREE:
    return {
      ...state,
      sectionThree: action.payload
    };
    case GET_SECTION_FOUR:
    return {
      ...state,
      sectionFour: action.payload
    };
    case GET_SECTION_FIVE:
    return {
      ...state,
      sectionFive: action.payload
    };
    case GET_SECTION_SIX:
    return {
      ...state,
      sectionSix: action.payload
    };

    default:
      return state;
  }
}
