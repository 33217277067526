import { 
    FETCH_COLLECTIONS,
    FETCH_COLLECTION_SCORES,
    FAILED_TO_FETCH_COLLECTIONS
} from "../actions/types";
  
  const initialState = {
    scores: [],
    collections: [],
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case FETCH_COLLECTIONS:
        return {
          ...state,
          collections: action.payload
      };
  
      case FETCH_COLLECTION_SCORES:
        return {
        ...state,
        scores: action.payload
      };
  
      case FAILED_TO_FETCH_COLLECTIONS:
        return {
          ...state,
          error: action.payload
        }
  
      default:
        return state;
    }
  }
  