import {
    GET_SCAN_NFT,
  } from "../actions/types";
  
  const initialState = {
    currentNFT: null,
    error: null,
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case GET_SCAN_NFT:
        return {
          ...state,
          currentNFT: action.payload
        };
      default:
        return state;
    }
  }
  