import React, { useState } from "react";
import Progress from "./paymentComponents/Progress";
import EmailInfo from "./paymentComponents/EmailInfo";
import CardPayment from "./paymentComponents/CardPayment";
import CheckCoupon from "./paymentComponents/CheckCoupon";
import Last from "./paymentComponents/Last";
import StepWizard from "react-step-wizard";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import close from "../../assets/close-outline-1.svg";
import "../../css/paywall.scss";

const transitions = {
  enterRight: `animated enterRight`,
  enterLeft: `animated enterLeft`,
  exitRight: `animated exitRight`,
  exitLeft: `animated exitLeft`,
  intro: `animated intro`,
};

const PaymentModal = ({
  visible,
  handleClose,
  selectedpaymentplan,
  setSelectedPplan,
  forFriend = false,
  user,
  modalLight,
}) => {
  const [form, setForm] = useState({ email: "", isYear: true });
  const [creditPaySuccess, setCreditPaySuccess] = useState(false);

  const updateForm = (key, value) => {
    form[key] = value;
    setForm({ ...form });
  };

  return (
    <Dialog
      className="modalPresent"
      open={visible}
      onClose={handleClose}
      scroll="paper"
      aria-labelledby="form-dialog"
    >
      <DialogContent>
        <img
          aria-label="close"
          className="modal-buttonClose"
          src={close} 
          alt="close icon"
          onClick={handleClose}
        />
        <div className="rsw-wrapper">
          <StepWizard transitions={transitions}>
            <EmailInfo
              user={user}
              forFriend={forFriend}
              updateForm={updateForm}
              formData={form}
              selectedpaymentplan={selectedpaymentplan}
              setSelectedPplan={setSelectedPplan}
              modalLight={modalLight}
            />
            <CardPayment
              forFriend={forFriend}
              form={form}
              creditPaySuccessFn={(x) => setCreditPaySuccess(x)}
              selectedpaymentplan={selectedpaymentplan}
              closeModal={handleClose}
            />
            <Progress />
            <Last
              creditPaySuccess={creditPaySuccess}
              selectedpaymentplan={selectedpaymentplan}
            />
            <CheckCoupon formData={form} updateForm={updateForm} />
          </StepWizard>
        </div>

        {/* {stepWizard && <Instance SW={stepWizard} />} */}
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({ user: state.firebase.auth });

export default connect(mapStateToProps)(PaymentModal);
