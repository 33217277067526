import React from "react"
import MaskedInput from "react-text-mask";

const TextMaskCardNumber = (props) => {
    const { inputRef, mask, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                if (inputRef && typeof inputRef === "function") {
                    inputRef(ref ? ref.inputElement : null);
                }          
            }}
            mask={[
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                "-",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                "-",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                "-",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
            ]}
            placeholderChar={"\u2000"}
        />
    );
}

export default TextMaskCardNumber;
