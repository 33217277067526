import {
  CREATE_COMMENT_LIKES, 
  CREATE_COMMENT, 
  GET_COMMENT, 
  COMMENT_ERROR,
} from "../actions/types";

const initialState = {
  likes: [],
  comments: [],
  comment: null,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CREATE_COMMENT_LIKES:
      return {
        ...state,
        likes: [...state.likes, action.payload]
      };
    case CREATE_COMMENT:
      return {
        ...state,
        comment: action.payload
      };
    case GET_COMMENT:
      return {
        ...state,
        comments: action.payload
      };
    case COMMENT_ERROR:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
}