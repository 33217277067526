import {
  GET_FUNDAMENTOS,
  GET_FUNDAMENTOS_POSTS,
  GET_GOOGLE_TRENDS,
  GET_CURATED_PROFILES,
  SET_CARTEIRA,
  GET_FUNDAMENTOS_ERROR,
} from "../actions/types";

const initialState = {
  fundamentos: [],
  error: null,
  posts: [],
  googleTrends: null,
  curatedProfiles: null,
  selectedCarteira: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_FUNDAMENTOS:
      return {
        ...state,
        fundamentos: action.payload,
      };
    case GET_CURATED_PROFILES:
      return {
        ...state,
        curatedProfiles: action.payload,
      };
    case GET_FUNDAMENTOS_POSTS:
      return {
        ...state,
        posts: action.payload,
      };
    case GET_GOOGLE_TRENDS:
      return {
        ...state,
        googleTrends: action.payload,
      };
    case SET_CARTEIRA:
      return {
        ...state,
        selectedCarteira: action.payload,
      };
    case GET_FUNDAMENTOS_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}
