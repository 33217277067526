import React, { Component } from "react";
import LinearProgress from '@material-ui/core/LinearProgress';

class Progress extends Component {
  state = {
    isActiveClass: "",
    timeout: null,
  }
  componentDidUpdate() {
    const { timeout } = this.state
    if (this.props.isActive && !timeout) {
      this.setState({
        isActiveClass: ".loaded",
        timeout: setTimeout(() => {
          this.props.nextStep()
        }, 3000),
      })
    } else if (!this.props.isActive && timeout) {
      clearTimeout(timeout)
      this.setState({
        isActiveClass: "",
        timeout: null,
      })
    }
  }
  render() {
    return (
      <div className="progress-wrapper">
        <p className="text-center">Pagamento em andamento...</p>
        <LinearProgress />
      </div>
    )
  }
}

export default Progress
