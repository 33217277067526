import React, { useState } from "react";
import Input from "@material-ui/core/Input";
import "react-toggle/style.css";
import axios from "axios";

import { getItemLocalStorage, getUserDetailsFromCookie } from "../../../utils/getCookies";
import pplansData from "../../../utils/pplansData";

function EmailInfo({
  updateForm,
  selectedpaymentplan,
  setSelectedPplan,
  nextStep,
  goToStep,
  formData,
  modalLight,
}) {
  const [termsValue, setTermsValue] = useState(false);
  const [visible, setVisible] = useState(false);
  const [message, setMessage] = useState("");
  const user = getUserDetailsFromCookie();
  const update = (e) => {
    updateForm(e.target.name, e.target.value);
  };

  const validate = (paymentMethode) => {
    formData.annualPlan = true;
    if (!formData || !formData.email) {
      if (!user || !user.email) {
        setVisible(true);
        setMessage("Digite um email válido.");
        return;
      }
      formData = { ...formData, email: user.email };
    }

    axios.post(`${process.env.REACT_APP_GOLANG_API}/orders`, {
      email: formData.email,
      landing: window.location.href,
    });

    if (!termsValue) {
      setMessage("Você precisa concorda com os termos para prosseguir!");
      setVisible(true);
      return;
    }

    if (paymentMethode === "card") {
      nextStep();
    } else {
      goToStep(3);
    }
  };

  const checkCoupon = () => {
    goToStep(5);
  };

  const pplanData = pplansData[selectedpaymentplan];

  const aplicateDiscount = (value) => {
    const discount = 1 - formData.discountPercentage / 100;
    const valueFormat = parseFloat(value).toFixed(2);
    return (discount * valueFormat).toFixed(2);
  };

  // const toggleAnnualPlan = () => {
  //   setAnnualPlan(!annualPlan);
  //   updateForm("isYear", !annualPlan);
  // };

  const onTermsChange = (ev) => {
    setTermsValue(ev.target.value);
  };

  const onChangePlan = (e) => {
    const plan = e.target.value;
    setSelectedPplan(plan);
  };

  return (
    <div className="card-email-scroll">
      <div className="contentCourse">
        <div className="descCourse">
          <div className="annualToggler">
            {/* <h5 className={annualPlan ? "" : "active"}>Quadrimestral</h5>
            <Toggle
              id="cheese-status"
              defaultChecked={annualPlan}
              onChange={toggleAnnualPlan}
            /> */}
            {/* <h5>
              Anual <span>25% OFF</span>
            </h5> */}
          </div>
          <div className="content-title">
            {!modalLight ? (
              <h5 className="title">{pplanData.title}</h5>
            ) : (
              <select
                className="select-modals"
                name="mdoalType"
                id="modalType"
                onChange={(e) => onChangePlan(e)}
              >
                <option value={3}>Paradigma Light</option>
                <option value={5}>Paradigma Pro</option>
              </select>
            )}
            <div className="content-price">
              {formData.discountPercentage && (
                <div
                  style={{
                    fontSize: "13px",
                    textDecoration: "line-through",
                  }}
                >
                  R${" "}
                  {pplanData.totalAnnualPrice}
                </div>
              )}
              <p className="total-price">
                R${" "}
                {formData.discountPercentage
                  ? aplicateDiscount(pplanData.totalAnnualPrice)
                  : pplanData.totalAnnualPrice
                }
              </p>
              <p className="price">
                (equivale R$
                {formData.discountPercentage
                  ? (
                      aplicateDiscount(pplanData.totalAnnualPrice) / 12
                    ).toFixed(2)
                  : pplanData.annualPrice
                }
                /mês)
                {formData.discountPercentage && (
                  <p className="price">
                    {`Cupom de ${formData.discountPercentage}% aplicado.`}
                  </p>
                )}
              </p>
            </div>
          </div>

          <div className="divisor"></div>
          <div className="content-itensCourse">
            <div>
              <p className="title-itensCourse">Incluso:</p>
              <ul className="itensCourse">{pplanData.itensCourse}</ul>
            </div>
            <div>
              <img src={pplanData.icon} alt="moeda pro" />
            </div>
          </div>
        </div>
      </div>
      {visible && (
        <div className="message-coupon" style={{ backgroundColor: "#F8D7DB" }}>
          <span style={{ color: "#842029" }}>{message}</span>
        </div>
      )}
      {!user && (
        <Input
          className="customInputEmail"
          value={formData.email}
          name="email"
          placeholder="Seu email"
          onChange={update}
          aria-describedby="basic-addon1"
          inputProps={{
            "aria-label": "YourEmail",
          }}
        />
      )}
      <div className="checkbox-terms-of-use">
        <input
          type="checkbox"
          className="checkbox"
          name="terms"
          checked={termsValue}
          data-name="Terms"
          onChange={(e) => {
            onTermsChange({
              target: {
                name: e.target.name,
                value: e.target.checked,
              },
            });
          }}
          id="terms"
          required
        />
        <span>
          Concordo com os
          <a
            href="https://paradigma.docsend.com/view/ku5u6ak"
            target="_blank"
            rel="noopener noreferrer"
          >
            Termos de uso e
          </a>
          <a
            href="https://paradigma.docsend.com/view/82h48x9"
            target="_blank"
            rel="noopener noreferrer"
          >
            Política de dados
          </a>
        </span>
      </div>
      <div className="text-center">
        <button
          className="btn btn-primary btn-payCard"
          onClick={async () => {
            validate("card");
            await axios.post(
              "https://umami.paradigma.education/api/collect",
              {
                type: "event",
                payload: {
                  website: "25c5308e-0c20-43e3-933b-44afc55e8a59",
                  url: `/feed`,
                  event_name: `Assinando ${formData.isYear ? 5 : 4}: /feed`,
                  event_data: `${window.location.href}`,
                },
              },
              {
                headers: {
                  "X-Umami-Cache": getItemLocalStorage("X-Umami-Cache"),
                },
              },
            );
          }}
        >
          Pagar com Cartão
        </button>
        <button
          className="btn btn-primary btn-coupon"
          onClick={() => {
            checkCoupon();
          }}
        >
          Tenho um Cupom de Desconto
        </button>
        <a
          href="https://paradigma.education/sobre-nos"
          target="_blank"
          rel="noopener noreferrer"
          className="btnPlan"
        >
          Quero ver depoimentos de assinantes
        </a>
      </div>
    </div>
  );
}

export default EmailInfo;
