import {
  GET_POSTS,
  GET_POST,
  GET_TRENDS_DATA,
  UPDATE_READING_PROGRESS,
  GET_POSTS_ERROR,
} from "../actions/types";

const initialState = {
  posts: [],
  currentPost: null,
  error: null,
  trendsData: {},
  readingProgress: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_POSTS:
      return {
        ...state,
        posts: action.payload,
      };
    case GET_POST:
      return {
        ...state,
        currentPost: action.payload,
      };
    case GET_TRENDS_DATA:
      return {
        ...state,
        trendsData: action.payload,
      };
    case UPDATE_READING_PROGRESS:
      return {
        ...state,
        readingProgress: action.payload,
      };
    case GET_POSTS_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}
