import {
  SET_USER,
  SET_READ_HISTORY,
  GET_COUPON_CODES,
  CREATE_NEW_COUPON,
  COUPON_CREATION_FAILED,
  SUBSCRIPTION_CANCELLING_ERROR,
  SUBSCRIPTION_CANCELLED,
  SUBSCRIPTION_UPDATED,
  SUBSCRIPTION_UPDATING_ERROR,
  SET_COUPON,
  SET_INSTALLMENTS,
} from "./types";
import { auth } from "../../config/firebase";
import axios from "axios";
import history from "../../history";
import {
  deleteCookie,
  deleteItemLocalStorage,
  getObjectFromCookie,
  getTokenFromCookie,
  getUserDetailsFromCookie,
  setCookie
} from "../../utils/getCookies";

export const setToken = async (user) => {
  try {
    const { token } = await user.getIdTokenResult();
    const sessionDetails = await axios.get(
      `${process.env.REACT_APP_GOLANG_API}/auth/generate`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    setCookie("_session", sessionDetails.data.token);
  } catch (error) {
    console.log(error);
  }
};

export const refreshToken = async (token) => {
  try {
    const sessionDetails = await axios.get(
      `${process.env.REACT_APP_GOLANG_API}/auth/generate`,
      {
        headers: { Authorization: `Bearer ${JSON.parse(token)}` },
      },
    );
    setCookie("_session", sessionDetails.data.token);
  } catch {
    try {
      const sessionDetails = await axios.get(
        `${process.env.REACT_APP_GOLANG_API}/auth/generate`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setCookie("_session", sessionDetails.data.token);
    } catch (error) {
      console.log(error);
    }
  }
};

export const setUser =
  (user, isFromLogin) =>
  async (dispatch, getState, { getFirebase, getFirestore, getAuth }) => {
    try {
      dispatch({
        type: SET_USER,
        payload: {
          ...user,
          name: user.displayName,
        },
      });

      if (isFromLogin) {
        await setToken(user);
        return;
      }
    } catch (error) {
      return error;
    }
  };

export const fetchCouponByUser = () => async (dispatch) => {
  const session = getObjectFromCookie("_session");
  try {
    const result = await axios.get(
      `${process.env.REACT_APP_GOLANG_API}/coupons/referral`,
      {
        headers: { Authorization: `Bearer ${session}` },
      },
    );
    const { coupon } = result.data;
    dispatch({
      type: SET_COUPON,
      payload: coupon,
    });
    return coupon;
  } catch (error) {
    await refreshToken(session);
    const newToken = getObjectFromCookie("_session");
    const result = await axios.get(
      `${process.env.REACT_APP_GOLANG_API}/coupons/referral`,
      {
        headers: { Authorization: `Bearer ${newToken}` },
      },
    );
    const { coupon } = result.data;
    dispatch({
      type: SET_COUPON,
      payload: coupon,
    });
    return coupon;
  }
};

export const fetchCouponsAndPurchases =
  (user, isFromLogin) =>
  async (dispatch, getState, { getFirebase, getFirestore, getAuth }) => {
    try {
      const session = getObjectFromCookie("_session");

      const result = await axios.get(`${process.env.REACT_APP_API}/coupons`, {
        headers: { Authorization: `Bearer ${session}` },
      });

      const { coupons, userCoupons, usersConverted, amountGenerated } =
        result.data;

      dispatch({
        type: GET_COUPON_CODES,
        payload: coupons,
        userCoupons,
        usersConverted,
        amountGenerated,
      });

      if (parseFloat(amountGenerated / 100).toFixed(2) > 200) {
        await axios.post(
          "https://us-central1-paradigma-firebase.cloudfunctions.net/sendEmail",
        );
      }
    } catch (error) {
      return error;
    }
  };

export const setNullUser = () => async () => {
  try {
    setAuthToken();
  } catch (error) {
    return error;
  }
};

export const createCouponObject = () => async (dispatch) => {
  try {
    const token = getTokenFromCookie();

    const url = `${process.env.REACT_APP_GOLANG_API}/coupons/referral-generate`;
    const data = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return dispatch({
      type: CREATE_NEW_COUPON,
      payload: {
        coupon: data.data.data,
      },
    });
  } catch (error) {
    return dispatch({
      type: COUPON_CREATION_FAILED,
      payload: {
        error: error.message,
      },
    });
  }
};

export const logOut =
  () =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    await auth.signOut().then(() => {
      setAuthToken();
      dispatch({
        type: SET_USER,
        payload: null,
      });
    });
    await deleteItemLocalStorage("user_details");
    await deleteItemLocalStorage("isNewUser");
    await deleteItemLocalStorage("isLogged");
    await deleteItemLocalStorage("emailForSignIn");
    await deleteCookie("_session");
    await deleteItemLocalStorage("nfts");
    await deleteItemLocalStorage("seePrize");
    window.location.href = window.location.origin + "/login";
  };

export const setAuthToken = (token) => {
  if (token) {
    // Apply to every req
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    // Delete auth header
    delete axios.defaults.headers.common["Authorization"];
  }
};

export const fetchUserInformation =
  () =>
  async (dispatch, { getFirestore }) => {
    try {
      const userData = getUserDetailsFromCookie();
      const u = await axios.get(
        `${process.env.REACT_APP_GOLANG_API}/users/${userData.id}`,
        {
          headers: { Authorization: `Bearer ${getTokenFromCookie()}` },
        },
      );
      dispatch({
        type: SET_USER,
        payload: { ...u, name: u.displayName },
      });
    } catch (error) {
      alert("Error fetching user information");
    }
  };

export const cancelSubscription = () => async (dispatch) => {
  try {
    const token = getTokenFromCookie();
    const response = await axios.patch(
      `${process.env.REACT_APP_API}/subscriptions/cancel`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    if (response.data.type === "error") {
      return dispatch({
        type: SUBSCRIPTION_CANCELLING_ERROR,
        payload: response.data.message,
      });
    }
    return dispatch({
      type: SUBSCRIPTION_CANCELLED,
      payload: response.data.message,
    });
  } catch (error) {
    return dispatch({
      type: SUBSCRIPTION_CANCELLING_ERROR,
      payload: error.response?.data?.error,
    });
  }
};

export const upgradeSubscription = () => async (dispatch) => {
  try {
    const token = getTokenFromCookie();
    const response = await axios.patch(
      `${process.env.REACT_APP_API}/subscriptions/update`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    dispatch({
      type: SUBSCRIPTION_UPDATED,
      payload: response.data.message,
    });
  } catch (error) {
    return dispatch({
      type: SUBSCRIPTION_UPDATING_ERROR,
      payload: error.response?.data?.error,
    });
  }
};

export const updateUser =
  ({ name }) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    try {
      let user = getUserDetailsFromCookie();
      await axios.patch(
        `${process.env.REACT_APP_GOLANG_API}/users/${user.id}/display-name`,
        { displayName: name },
        {
          headers: { Authorization: `Bearer ${getTokenFromCookie()}` },
        },
      );
      await refreshToken(getTokenFromCookie());
      user = getUserDetailsFromCookie();
      await dispatch({
        type: SET_USER,
        payload: { ...user, name: user.displayName },
      });
      return true;
    } catch (error) {
      return error;
    }
  };

export const updateReadHistory =
  ({ postID, progress }) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    try {
      const firestore = getFirestore();
      let user;

      const token = getTokenFromCookie();

      const response = await axios.get(
        `${process.env.REACT_APP_API}/user/information`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      user = response.data.user;

      let readHistory = {
        ...user.readHistory,
        [postID]: progress,
      };

      await firestore.collection("users").doc(user.id).update({
        readHistory,
      });

      dispatch({
        type: SET_READ_HISTORY,
        payload: readHistory,
      });
    } catch (error) {
      return error;
    }
  };

export const authenticateUser =
  (userDetails) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    history.push("/minhaconta");
  };

export const setSubscriptionStatus = (status) => async (dispatch) => {
  dispatch({
    type: "SET_SUBSCRIPTION_STATUS",
    payload: status,
  });
};

export const setInstallments = (installments) => (dispatch) => {
  try {
    dispatch({
      type: SET_INSTALLMENTS,
      payload: installments,
    });
  } catch (err) {
    alert("Error setting installments");
  }
};
