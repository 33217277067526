import React from "react";
import { render, hydrate } from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store/store";

const environment =
  process.env.REACT_APP_ENVIRONMENT || process.env.NODE_ENV || "development";
Sentry.init({
  dsn: `${process.env.REACT_APP_SENTRY_DNS}`,
  integrations: [new Integrations.BrowserTracing()],
  environment,
  tracesSampleRate: environment === "development" ? 1.0 : 0.1,
});

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(
    <Provider store={store}>
      <App />
    </Provider>,
    rootElement
  );
} else {
  render(
    <Provider store={store}>
      <App />
    </Provider>,
    rootElement
  );
}
