import { GET_GRAFICOS, GET_GRAFICOS_POSTS } from "../actions/types";

const initialState = {
  graficos: [],
  posts: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_GRAFICOS:
      return {
        ...state,
        graficos: action.payload
      };
    case GET_GRAFICOS_POSTS:
      return {
        ...state,
        posts: action.payload
      };

    default:
      return state;
  }
}
