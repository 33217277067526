import React, { useState } from 'react';

const GlobalContext = React.createContext();

const GlobalProvider = ({ children }) => {
    const [foundCollectionsAfterSearch, setFoundCollectionsAfterSearch] = useState('');
    const [currentCollection, setCurrentCollection] = useState('cryptopunks');
    const [selectCollectionSidebar, setSelectCollectionSidebar] = useState('');
    const [selectedModel, setSelectedModel] = useState('s2f');
    const [selectCarteira, setSelectCarteira] = useState('unarchive');
    const [errorScan, setErrorScan] = useState(false);
    const [selectedCard, setSelectedCard] = useState({});
    const [selectedCardIndex, setSelectedCardIndex] = useState(0);
  
    return (
      <GlobalContext.Provider
        value={{
            setFoundCollectionsAfterSearch,
            setCurrentCollection,
            foundCollectionsAfterSearch,
            currentCollection,
            setSelectCollectionSidebar,
            selectCollectionSidebar,
            setSelectedModel,
            selectedModel,
            setSelectCarteira,
            selectCarteira,
            setErrorScan,
            errorScan,
            setSelectedCard,
            selectedCard,
            setSelectedCardIndex,
            selectedCardIndex
        }}
      >
        {children}
      </GlobalContext.Provider>
    );
  };
  
  const useGlobalContext = () => React.useContext(GlobalContext);
  
  export default GlobalContext;
  
  export { GlobalProvider, useGlobalContext };