import {
  SET_USER,
  LOGOUT,
  SET_COUPON,
  SET_READ_HISTORY,
  SET_REFRESH,
  SET_UNREAD_NOTIFICATIONS,
  GET_COUPON_CODES,
  CREATE_NEW_COUPON,
  COUPON_CREATION_FAILED,
  SUBSCRIPTION_CANCELLED,
  SUBSCRIPTION_CANCELLING_ERROR,
  SUBSCRIPTION_UPDATING_ERROR,
  SUBSCRIPTION_UPDATED,
  SET_INSTALLMENTS,
} from "../actions/types";

const initialState = {
  user: null,
  isLoaded: false,
  readHistory: [],
  refreshed: false,
  coupons: [],
  userCoupons: [],
  usersConverted: [],
  amountGenerated: 0.0,
  coupon: {},
  subscriptionError: "",
  subscriptionCancelled: "",
  subscriptionUpdateError: "",
  subscriptionUpdated: "",
  subscriptionStatus: null,
  installments: 1,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.payload,
        readHistory: action.payload ? action.payload.readHistory || {} : {},
        isLoaded: true,
      };
    case GET_COUPON_CODES:
      return {
        ...state,
        coupons: action.payload,
        userCoupons: action.userCoupons,
        usersConverted: action.usersConverted,
        amountGenerated: action.amountGenerated,
      };
    case SET_UNREAD_NOTIFICATIONS:
      return {
        ...state,
        unreadNotifications: action.payload,
      };
    case CREATE_NEW_COUPON:
      return {
        ...state,
        coupon: action.payload,
      };
    case SET_COUPON:
      return {
        ...state,
        coupon: action.payload,
      };
    case COUPON_CREATION_FAILED:
      return {
        ...state,
        couponError: action.payload,
      };
    case SUBSCRIPTION_CANCELLING_ERROR:
      return {
        ...state,
        subscriptionError: action.payload,
      };
    case SUBSCRIPTION_CANCELLED:
      return {
        ...state,
        subscriptionCancelled: action.payload,
      };
    case SUBSCRIPTION_UPDATING_ERROR:
      return {
        ...state,
        subscriptionUpdateError: action.payload,
      };
    case SUBSCRIPTION_UPDATED:
      return {
        ...state,
        subscriptionUpdated: action.payload,
      };
    case SET_READ_HISTORY:
      return {
        ...state,
        readHistory: action.payload,
      };
    case SET_REFRESH:
      return {
        ...state,
        refreshed: action.payload,
      };

    case LOGOUT:
      return {
        user: null,
      };

    case "SET_SUBSCRIPTION_STATUS":
      return {
        ...state,
        subscriptionStatus: action.payload,
      };

    case SET_INSTALLMENTS:
      return {
        ...state,
        installments: action.payload,
      };

    default:
      return state;
  }
}
