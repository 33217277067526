import { combineReducers } from "redux";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";
import authReducer from "./authReducer";
import postsReducer from "./postsReducer";
import userReducer from "./userReducer";
import graficosReducer from "./graficosReducer";
import technicalAnalysisReducer from "./technicalAnalysisReducer";
import fundamentosReducer from "./fundamentosReducer";
import indicadoresReducer from "./indicadoresReducer";
import modelosReducer from "./modelosReducer";
import searchReducer from "./searchReducer";
import { videoStats } from "./videoReducer";
import collectionReducer from "./collectionReducer";
import scanReducer from "./scanReducer";
import trilhasReducer from "./trilhasReducer";
import reactionsReducer from "./reactionsReducer";
import commentReducer from "./commentReducer";

export default combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  auth: authReducer,
  posts: postsReducer,
  user: userReducer,
  graficos: graficosReducer,
  technicalAnalysis: technicalAnalysisReducer,
  fundamentos: fundamentosReducer,
  indicadores: indicadoresReducer,
  modelos: modelosReducer,
  search: searchReducer,
  videoStats: videoStats,
  collections: collectionReducer,
  scan: scanReducer,
  trilhas: trilhasReducer,
  reactions: reactionsReducer,
  commentLikes: commentReducer,
});
