import {
  GET_ENERGY,
  GET_S2F,
  GET_METCALFE,
  GET_MODEL_DATA,
  GET_MODEL_ERROR,
} from "../actions/types";

const initialState = {
  energy: null,
  s2f: null,
  metcalfe: null,
  modelData: null,
  error: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_MODEL_DATA:
      return {
        ...state,
        modelData: action.payload
      };
    case GET_ENERGY:
      return {
        ...state,
        energy: action.payload
      };
    case GET_S2F:
      return {
        ...state,
        s2f: action.payload
      };
    case GET_METCALFE:
      return {
        ...state,
        metcalfe: action.payload
      };
    case GET_MODEL_ERROR:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
}
