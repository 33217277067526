import React, { useState } from "react";
import imgCoupon from "../../../assets/image-5.png";
import arrowBack from "../../../assets/arrow-back-outline-1-1.svg";
import loadingGif from "../../../assets/loading-button.gif";
import axios from "axios";
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';

const CheckCoupon = (props) => {
  const { goToStep, updateForm } = props;
  const [coupon, setCoupon] = useState("");
  const [loading, setLoading] = useState(false);
  const [couponChecked, setCouponChecked] = useState(false);
  const [isCouponValid, setIsCouponValid] = useState(false);
  const [discountPercentage, setDiscountPercentage] = useState(null);
  const [message, setMessage] = useState("Cupom Inválido.");

  const handleInputChange = (event) => {
    event.preventDefault();
    const target = event.target;
    let targetValue = target.value.replace(/(\.\/)/g, "").trim();
    
    setCoupon(targetValue);
    updateForm(target.name, targetValue);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    setLoading(true);
    setCouponChecked(false);
    setIsCouponValid(false);

    try {
      const getCouponURL = `${process.env.REACT_APP_GOLANG_API}/coupons/verify`;
    
      axios
        .post(getCouponURL, { code:  coupon })
        .then(async (response) => {
          setLoading(false);
          if (response.data.coupon.active) {
            let discountPercentage = response.data.coupon.coupon.percent_off;
            setCouponChecked(true);
            setIsCouponValid(true);
            setDiscountPercentage(discountPercentage);
    
            updateForm("discountPercentage", discountPercentage);
            updateForm("coupon", response.data.coupon);

            setTimeout(()=> goToStep(1), 2000);
          } else {
            setLoading(false);
            setCouponChecked(true);
            setIsCouponValid(false);
            setMessage("Cupom está vencido ou não é mais valido.");
          }
        })
        .catch((error) => {
          setLoading(false);
          setCouponChecked(true);
          setIsCouponValid(false);
          console.error(error);

          updateForm("discountPercentage", null);
          setTimeout(() => goToStep(1),2000);
          
          return
        }); 
    } catch (error) {
      console.log(error);
    }
  }

  return <>
    <img 
      className="btn-prev" 
      src={arrowBack}
      alt="arrow back"
      onClick={() => goToStep(1)} 
      style={{outline: 'none'}}
    />
    <div className="data-coupon">
      <img src={imgCoupon} alt="Coupon" />
      {couponChecked && isCouponValid && (
        <div className="message-coupon" style={{ backgroundColor: "#68ffbf" }}>
          <span style={{ color: "#126C46" }}>
            {`Cupom de ${discountPercentage}% aplicado!`}
          </span>
        </div>
      )}
      {couponChecked && !isCouponValid && (
        <div className="message-coupon" style={{ backgroundColor: "#F8D7DB" }}>
          <span style={{ color: "#842029" }}>
            {message}
          </span>
        </div>
      )}
      <form autoComplete="off" id="form-coupon" onSubmit={handleSubmit}>
        <FormControl>
          <label className="label-form-coupon">Insira seu cupom de desconto</label>
          <Input
            placeholder="Seu Cupom"
            aria-label="MeuCupom"
            aria-describedby="basic-addon1"
            style={{
              color: couponChecked
                ? isCouponValid
                  ? "#27224E"
                  : "#F06878"
                : "",
            }}
            name="coupon"
            value={coupon.toUpperCase()}
            onChange={handleInputChange}
          />
        </FormControl>
        <div>
          <button
            className="btn btn-primary btn-form-coupon"
            type="submit"
            disabled={loading}
          >
            {loading 
              ? <img style={{ width: "20px", margin: "0"}} src={loadingGif} alt="loading" /> 
              : `Aplicar`
            }
          </button>
        </div>
      </form>
    </div>
  </>
}

export default CheckCoupon;
