import React from "react";
import credCard from "../../../assets/icon3.jpg";
import masterVisa from "../../../assets/icon4.jpg";
import sadEmoji from "../../../assets/icon5.jpg";

const Last = (props) => {
  const { creditPaySuccess, goToStep } = props;

  if (creditPaySuccess) {
    setTimeout(function () {
      window.location.href = "https://app.paradigma.education";
    }, 3000);

    return (
      <>
        <div className="modal2">
          <img src={credCard} className="modal2img1" alt="" />
          <h2>Pagamento aprovado</h2>
          <p>Voc&ecirc; ser&agrave; redirecionado</p>
          <img src={masterVisa} alt="" />
        </div>
      </>
    );
  }

  return (
    <>
      <div className="modal3">
        <h2>
          Algo deu <span>errado</span>
        </h2>
        <img src={sadEmoji} alt="" />
        <h5>
          Que tal{" "}
          <a href="/" onClick={() => goToStep(2)}>
            tentar de novo
          </a>
          ?
        </h5>
        <p>
          Talvez voc&ecirc; tenha deixado a janela aberta e esquecido da
          transfer&ecirc;ncia...
        </p>
      </div>
    </>
  );
};

export default Last;
