import React, { useEffect } from "react";
import "react-day-picker/lib/style.css";
import "./App.css";
import "./DarkMode.scss";
import axios from "axios";
import { connect } from "react-redux";

import { setUser, setNullUser } from "./store/actions/authActions";
import "./config/firebase";
import { auth } from "./config/firebase";
import "./config/API";
import Routes from "./components/layouts/Routes";
import { QueryClient, QueryClientProvider } from "react-query";
import { GlobalProvider } from "./context";
import { CookiesProvider } from "react-cookie";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = ({ setUser, authLoaded, setNullUser }) => {
  useEffect(() => {
    const data = {
      type: "pageview",
      payload: {
        website: "6d47ac67-8dd8-40f8-a8f1-a56b14d9cb00",
        hostname: "app.paradigma.education",
        url: "/",
        referrer: "",
      },
    };
    axios
      .post("https://umami.paradigma.education/api/collect", data, {
        headers: { "x-umami-cache": null },
      })
      .then((response) => {
        window.localStorage.setItem("X-Umami-Cache-app", response.data);
      });
  });
  useEffect(() => {
    const data = {
      type: "pageview",
      payload: {
        website: "712a08b6-99df-434a-8610-21618677c41d",
        hostname: "app.paradigma.education",
        url: "/",
        referrer: "",
      },
    };
    axios
      .post("https://umami.paradigma.education/api/collect", data, {
        headers: { "x-umami-cache": null },
      })
      .then((response) => {
        window.localStorage.setItem("X-Umami-Cache-post", response.data);
      });
  });
  useEffect(() => {
    const data = {
      type: "pageview",
      payload: {
        website: "cc822bc0-6683-49bc-8d3f-752d41b76ed0",
        hostname: "app.paradigma.education",
        url: "/",
        referrer: "",
      },
    };
    axios
      .post("https://umami.paradigma.education/api/collect", data, {
        headers: { "x-umami-cache": null },
      })
      .then((response) => {
        window.localStorage.setItem("X-Umami-Cache-kpis", response.data);
      });
  });
  useEffect(() => {
    const data = {
      type: "pageview",
      payload: {
        website: "a997fcbb-3322-4ddd-adbf-ce5da82cf349",
        hostname: "app.paradigma.education",
        url: "/",
        referrer: "",
      },
    };
    axios
      .post("https://umami.paradigma.education/api/collect", data, {
        headers: { "x-umami-cache": null },
      })
      .then((response) => {
        window.localStorage.setItem("X-Umami-Cache-trilhas", response.data);
      });
  });
  useEffect(() => {
    auth.onAuthStateChanged((newUser) => {
      if (!newUser) {
        setNullUser();
      }

      if (newUser) {
        setUser(newUser);
      }
    });
  }, [setNullUser, setUser]);

  if (!authLoaded) return null;

  return (
    <QueryClientProvider client={queryClient}>
      <GlobalProvider>
        <CookiesProvider>
          <div className="App">
            <Routes />
          </div>
        </CookiesProvider>
      </GlobalProvider>
    </QueryClientProvider>
  );
};

const mapStateToProps = (state) => ({
  authLoaded: state.firebase.auth.isLoaded,
});

export default connect(mapStateToProps, { setUser, setNullUser })(App);
